<template>
  <v-row>
    <v-col cols="12" v-if="loadInfo">
      <v-skeleton-loader
        type="list-item-avatar-three-line, list-item-two-line"
      ></v-skeleton-loader>
    </v-col>
    <v-col cols="12" class="pt-0" v-else>       
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon
              color="secondary"
              class="blue-grey lighten-5"
              dark
            >
              mdi-account
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle class="caption blue-grey--text text--darken-3">Nombre Legal</v-list-item-subtitle>
            <v-list-item-title class="font-weight-bold primary--text" v-text="info.ven_des"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider class="mb-4"></v-divider>
      <v-row class="px-4">
        <v-col cols="12" sm="6" md="4">
          <span class="caption d-block text-uppercase blue-grey--text text--darken-3 mr-2">
            Cédula:
          </span>
          <span class="font-weight-bold primary--text" v-text="info.cedula" />
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <span class="caption d-block text-uppercase blue-grey--text text--darken-3 mr-2">
            Email:
          </span>
          <span class="font-weight-bold primary--text" v-text="info.email" />
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <span class="caption d-block text-uppercase blue-grey--text text--darken-3 mr-2">
            Teléfonos:
          </span>
          <span class="font-weight-bold primary--text" v-text="info.telefonos" />
        </v-col>
      </v-row>
      <v-row class="px-4">
        <v-col cols="12">
          <span class="caption text-uppercase d-block blue-grey--text text--darken-3 mr-2">
            Dirección:
          </span>
          <span class="font-weight-bold primary--text" v-text="info.direc1" />
        </v-col>
      </v-row>
    </v-col>   
  </v-row>
</template>
<script>
export default {
  name: 'InfoBasica',
  props:{
    info: {
      type: Object,
      default: () => ({})
    },
    loadInfo: Boolean

  },
}
</script>
